import * as React from "react";

import { useRecordContext } from 'react-admin';

export const EpisodeCounter = (props: any) => {
    const record = useRecordContext(props);
    if (record.episode_number == null) {
        return <span></span>;
    } else if (record.episodes_found == null) {
        return <span>??/{record.episode_number}</span>;
    } else {
        return <span>{record.episodes_found}/{record.episode_number}</span>;
    }
}