import "./App.css";

import { Admin, Resource, fetchUtils } from 'react-admin';

import { ProductList } from './ProductList';
import { ProductShow } from './ProductView';
import React from "react";
import simpleRestProvider from 'ra-data-simple-rest';
import { authProvider } from "./auth/authProvider";
import LoginPage from "./auth/LoginPage";
import { createBrowserHistory } from 'history';


const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }
  // const token = localStorage.getItem('token');
  // options.headers.set('Authorization', `Bearer ${token}`);
  options.credentials = 'include';
  return fetchUtils.fetchJson(url, options);
}
const dataProvider = simpleRestProvider('https://listing-api.saas.tozorlabs.com/api', httpClient)
const history = createBrowserHistory();


function App() {
  return (
    <Admin
      authProvider={authProvider}
      loginPage={LoginPage}
      dataProvider={dataProvider}
      history={history}
      title="SAAS series listing"
    >
      <Resource name="last_week"
        list={ProductList}
        show={ProductShow}
        options={{label: "Last week"}}
      />
      <Resource name="last_month"
        list={ProductList}
        show={ProductShow}
        options={{label: "Last month"}}
      />
      <Resource name="series"
        list={ProductList}
        show={ProductShow}
      />
      <Resource name="movies"
        list={ProductList}
        show={ProductShow}
      />
      <Resource name="variety"
        list={ProductList}
        show={ProductShow}
        options={{label: "Variety"}}
      />
      <Resource name="anime"
        list={ProductList}
        show={ProductShow}
        options={{label: "Anime"}}
      />
    </Admin>
  );
}

export default App;
